import { Routes, Route } from "react-router-dom";

import Layout from './components/layout/layout';
import Home from './containers/home';
import AboutUs from './containers/about-us';
import Clients from './containers/clients';
import AreasCovered from './containers/areas-covered';
import PayAndDisplay from './containers/pay-and-display';
import Residential from './containers/residential';
import Appeals from './containers/appeals';
import Contact from './containers/contact';
import PrivacyPolicy from './containers/privacy-policy';

import './App.css';

function App() {
  return (
    <div className="bg-gray-200">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" index element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/clients" element={<Clients />} />
          <Route path="/areas" element={<AreasCovered />} />
          <Route path="/pay-and-display" element={<PayAndDisplay />} />
          <Route path="/residential" element={<Residential />} />
          <Route path="/appeals" element={<Appeals />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
