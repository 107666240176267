import React from 'react';
import { Outlet } from 'react-router-dom';

import Header from './components/header';
import Footer from './components/footer';

import "./layout.scss";

const siteName = process.env.REACT_APP_SITE_NAME || 'Premier Parking Logistics';
const phone = process.env.REACT_APP_PHONE_NUMBER || '';

function Layout() {
  return (
    <React.Fragment>
      <div className="bg-black xl:bg-white">
        <div className="mx-auto container h-28 flex justify-between items-center">
          <div className="hidden xl:block" style={{float: 'left'}}>
            <div className="flex flex-row justify-start text-left">
              <div className="logo-block bg-orange h-20 "></div>
              <div className="logo-block bg-gray-600 h-20"></div>
              <div className="logo-block bg-black h-20"></div>
            </div>
            <div className="flex space-x-20">
              {siteName === "Premier Parking Enforcement LTD" ? (
                <>
                  <p>Premier</p>
                  <p>Parking</p>
                  <p>Enforcement LTD</p>
                </>
              ) : (
                <>
                  <p>Premier</p>
                  <p>Parking</p>
                  <p>Logistics</p>
                </>
              )}
            </div>
          </div>
          <div className="mx-auto text-white xl:mx-0 xl:text-black text-center xl:text-right pr-2">
            <p>Pay by phone - ℡ {phone} - Open 24 Hour</p>
          </div>
        </div>
      </div>
      
      <Header />
      <div className='mx-auto container bg-white h-auto'>
        <Outlet />
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default Layout;
