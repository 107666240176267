import React from 'react';
import Splash from '../../components/splash';

import img from './img.jpg';
import PageTitle from '../../components/page-title';

const Residential = () => (
  <div className='h-screen'>
    <Splash src={img} title='Residential Management' />
    <div className='p-4'>
      <PageTitle title='Residential Management' />
      <div className='space-y-4'>
        <p>We offer full residential solutions to ensure that your residential parking is reserved for the residents and guests, rather than strangers stealing your spaces.</p>

        <p>We can offer you all the equipment and tools to manage your own carpark using our advanced iview system, which is tailor made to ensure that only authorised people can use the specific carpark.</p>

        <p>Using the iview system you can also allow temporary authorisation for visiting vehicles, so you don't need to worry about genuine visitors getting a PCN.</p>

        <p><b>Reasons for residential parking management</b></p>
        <ul className='list-disc list-inside space-y-2 text-lg'>
          <li>Never need to worry about your space</li>
          <li>Generate additional income from offending vehicles</li>
          <li>Easier parking for friends and family to visit</li>
          <li>More security, with no strange vehicles parked outside your residence</li>
        </ul>
      </div>
    </div>
  </div>
);

export default Residential;

