import "./privacy-policy.scss";
import PageTitle from '../../components/page-title';

const siteName = process.env.REACT_APP_SITE_NAME;
const siteUrl = process.env.REACT_APP_SITE_URL;

function PrivacyPolicy() {
  return (
    <div className="h-auto p-4 pb-32 space-y-4">
      <PageTitle title='Privacy Policy' />
      <p>In this Privacy Policy references to "us" and "we" are to Premier Parking Logistics</p>

      <p>This Privacy Policy governs the way that we collect, process, share and retain Personal Data collected from users of our websites ({siteUrl} and www.payppl.co.uk) motorists and/or registered keepers of vehicles, our clients and their representatives collectively referred to as "you" and "your."</p>
      <div></div>
        <h2>Personal Data</h2>
        <p>We may collect your Personal Data by a variety of means, including, but not limited to:</p>
        <ul>
          <li>Camera images including, CCTV, ANPR, Operative Body and other Operative Cameras</li>
          <li>Operatives via Enforcement Software including App</li>
          <li>The DVLA</li>
          <li>Information you provide to us including via our websites, by telephone, post, email, pay and display machines, registration points, permit systems</li>
        </ul>

        <p>The types of personal data may include but are not limited to:</p>
        <ul>
          <li>Name</li>
          <li>Address</li>
          <li>Vehicle Registration Number (VRN)</li>
          <li>Telephone Numbers</li>
          <li>Email Addresses</li>
          <li>Payment Information including credit/debit card details</li>
        </ul>

        <h3>Web Browser Cookies</h3>
        <p>Our websites may use cookies to enhance user experience. Your browser may place cookies on your hard drive for record-keeping purposes and sometimes to track information about your use of the website. You may choose to set your web browser to refuse such cookies, or to alert you when cookies are being sent.</p>

        <h2>How we use Personal Data</h2>
        <p>We use your Personal Information for the following purposes:</p>
        <ul>
          <li>To process payments (we do not share Payment Information with outside parties except to the extent necessary to provide the service)</li>
          <li>For the management of our parking services including permit systems, payments and parking enforcement</li>
          <li>To issue and pursue a Parking Charge Notice where necessary</li>
          <li>To obtain information from the DVLA</li>
          <li>For appeals including IAS (Independent Appeals Service)</li>
          <li>Enforcement including before the Court</li>
          <li>Statistical analysis of our car park management services</li>
          <li>As may be required by the police or appropriate security organisations to prevent or detect crime</li>
        </ul>

        <p>We only disclose Personal Data as required for the provision of our Services (including enforcement of PCNs or court action) as required by law. Where we need to give information to agents or subcontractors in providing our services we ensure that the information is safe and secure. We do not sell, trade or rent your personal information to others.</p>

        <p>We process collect, process, share and retain your Personal Data in accordance with the lawful grounds of legitimate interest and the performance of a contract.</p>

        <h3>Third Party websites</h3>
        <p>You may find links to sites and services of third parties (such as the ICO or IPC). We do not control the content or links that appear on these sites and are not responsible for the practices employed by such sites.</p>

        <h3>Protecting Your Information</h3>
        <p>We adopt appropriate collection, storage and processing practices and security measures to protect against unauthorised access, alteration, disclosure or destruction of Personal Data. All our employees and other data processors are obligated to respect the confidentiality of your Personal Data.</p>

        <h3>Your Rights</h3>
        <p>You have certain rights as to how we may handle your Personal Data. These are set out in detail at www.ico.org.uk. In particular, you have right to object or restrict our processing of your Personal Data in certain circumstances.</p>

        <p>Please note that your rights are not absolute and may not prevent or restrict our processing your Personal Data should such processing be for the establishment, exercise or defence of legal claims (ie. Where we may process your Personal Data for the purpose of Court action to enforce a PCN or other debt) or where our legitimate interests may override your interests, rights and freedoms in accordance with current legislation. Should you request a restriction on processing which we reasonably consider to be manifestly unfound or excessive we may refuse such request unless you pay a reasonable fee to allow us to deal with the request.</p>

        <p>Reasonable fees are calculated based on an hourly rate of £20 per hour to be spent dealing with such a request together with reasonable photocopying and postage costs. Such fees will be charged in advance based on a reasonable estimate of the time to be taken in complying with the request but, should this estimate be insufficient, a balancing payment for any additional administrative time may be charged before your request is actioned. Should any extra fee be paid (in excess of 15 minutes or £5) compared to the time spent this be returned to you when the request is actioned.</p>

        <h3>Changes to the Privacy Policy</h3>
        <p>We may at our discretion update this policy at any time and will generally review it at least annually. We would encourage you to frequently check back for any updates which may affect you.</p>

        <h3>Contacting Us</h3>
        <p>If you have any queries about this Privacy Policy or Personal Data, please contact us at:</p>

        <p>Office 3, Block 4 Mint Drive, Hockley, Birmingham, B18 6EA or at <a href={`mailto:info@${siteUrl}`}>info@{siteUrl}</a></p>
      </div>
  );
}

export default PrivacyPolicy;
