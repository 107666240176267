import Splash from '../../components/splash';
import img from './parked-cars.jpg';
import PageTitle from '../../components/page-title';

function Clients() {
  return (
    <div className='h-screen text-center'>
      <Splash src={img} title="Our Clients" />
      <div className='clients content-body p-4'>
        <PageTitle title="Our Clients" />
        <div className='text-lg'>
          <p className="mb-4">Through our many years in the parking management industry, we have worked with many large and small companies. Which we continue to help and grow their parking solutions as there circumstances change.</p>
          <p className="mb-4">Some of our clients include Coral, La Fitness, Network Rail and many more brand name companies as well as numerous small businesses and land owners.</p>
          <p className="mb-4">If you would like to join the catalogue of businesses that have benefitted from our stress-free parking management and enforcement, get in touch today to see how are fine tuned expert parking services can help you solve your parking issues and of course maximise your potential income generated through offending vehicles.</p>
        </div>
      </div>
    </div>
  );
}

export default Clients;