import PageTitle from '../../components/page-title';
import Splash from '../../components/splash';
import img from './carpark-above.jpg';

function AboutUs() {
  return (
    <div className="h-screen">
      <Splash title='A little about us' src={img} />
      <div className='p-4 text-center'>
        <PageTitle title='A little about us' />

        <strong>Here at premier parking we aim to help each of our clients gain the maximum potential from their land regardless of the size, by offering individual services for each of our clients.</strong>

        <p>We have many years of experience in the parking industry and in that time we have gained a library of knowledge to rival all other parking companies, which gives us a great advantage when creating parking solutions and analysing the potential of your land, to ensure you get the very best service to suite your specific needs.</p>

        <p>We have a wealth of knowledge of how this industry works, and we use that to help our clients, we don't try to be the biggest company around but we do try to be the best.</p>
      </div>
    </div>
  );
}

export default AboutUs;

