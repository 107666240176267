import Splash from '../../components/splash';
import PageTitle from '../../components/page-title';

import img from './img.jpg';

const Appeals = () => (
  <div className='h-full pb-32'>
    <Splash src={img} title='Parking Ticket Appeals' />
    <div className='p-4'>
      <PageTitle title='Parking Ticket Appeals' />
      <div className='space-y-4'>
        <h2 className='text-xl text-amber-500'>General Overview</h2>
        <p>
          PCN'S and charges, especially those issued on private land are hot topics for debate often fuelled by armchair lawyers who use various media such as the internet as their soapbox - As with many aspects of the internet this advice is very often ill founded and the people giving it are not legally qualified in any way and very often hide behind aliases and appear on many forums under multiple identities.</p>
        <p>
          Charges issued by Premier Parking Logistics are based on agreement to pay a set parking charge, in the majority of cases this is  &pound;60 if paid within 14-days - for parking charges not paid and referred for legal collection the charge increases to  &pound;160 or more to cover additional administrative expenses to enforce the debt plus legal costs (these are clearly defined at the time the contract is made).
        </p>
        <p>
        When a PCN is issued on behalf of Premier Parking Logistics we ensure that photographic or CCTV evidence is taken thereby showing the vehicle parked in clear view of a sign and therefore by the actions of parking you are deemed to have accepted all conditions on display.</p>
        <p>
        Premier Parking Logistics are one of the few companies that rigorously pursue unpaid parking charges via the courts and all cases are dealt with by our team of in-house legal experts - thereby ensuring a consistent enforcement policy.</p>
        <p>
        The statement that parking companies never win cases is a total myth and we produce a small sample of cases taken to court by Premier Parking Logistics with the company and defendant present, many different avoidance defenses were used but the court was satisfied that the parking charges were issued correctly, the signage clear and of a type that is bound to have been seen by a reasonable person and more importantly that our parking charges were fair.
        </p>

        <p>
          Unfortunately many of the cases taken to court have been led into the belief that parking charges were not enforceable and by defending a case it would either be not pursued or be thrown out - this advice has come from various internet/advice forums and has resulted in an original charge of &pound;60 costing the defendant at least 3-times that amount, a County Court Judgement being issued (which could make getting credit very difficult for upto 6years) and time/money spent by a defendant preparing a defence and attending court.</p>

        <h2 className='text-xl text-amber-500'>Parking Charge Notices Appeals</h2>

        <p>
        All offenders can appeal against a PCN by following the procedure below:
        </p>
        <p>All appeals must be in writing or email only (they will not be dealt with over the phone)</p>

        <p>All appeals must be received in our offices within 14 days of the date of the offense as dated on the receipt in order to freeze the payment at a lower rate.</p>

        <p>
          All appeals will be responded to within 28 days.
          Appeals can be made by post. 
        </p>

        <p>
          Please Note: Should payment or appeal of the PCN not be received within 21 days of the date issued, the matter will be passed to our Debt Recovery Agents. At this point any discounted charge will no longer apply.
        </p>

        <h2 className='text-xl text-amber-500'>Photographic Evidence</h2>
        <p>
          Offenders should note before making an appeal that all vehicles are photographed by digital camera, which is date and time-stamped showing the offense committed.
        </p>

        <p>
          Photographs are for internal use only and they will only be passed over to a specialist debt collection agency and used as prosecuting evidence in any court case against you.
        </p>

        <h2 className='text-lg font-bold text-amber-500'>Please note that we do not enter into multiple appeals.</h2>
        <p>To make an appeal, please use the contact form on the contact page using the following link: <a href="contact">Contact</a></p>
      </div>
    </div>
  </div>
);


export default Appeals;

