import { useState } from 'react';
import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';
import fetch from 'isomorphic-fetch';
import Spinner from 'react-bootstrap/Spinner';
import img from './contact-img.jpeg';
import PageTitle from '../../components/page-title';
import Splash from '../../components/splash';

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short')
    .max(50, 'Too Long')
    .required('Required'),
  message: Yup.string()
    .min(2, 'Too Short')
    .max(500, 'Too Long')
    .required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  address: Yup.string()
    .min(5, 'Too Short')
    .max(500, 'Too Long'),
  referenceNumber: Yup.string()
    .min(3, 'Too Short')
    .max(25, 'Too Long')
});

const API_ROOT = 'https://0b52nyxc0j.execute-api.eu-west-1.amazonaws.com/dev/contact-form';

function Contact() {
  return (
    <div className='h-screen'>
      <Splash src={img} title='Contact' />
      <div className='p-4'>
        <PageTitle title='Contact' />
        <p>Please email us with your address and reference number: 
          {' '}<a href="mailto:info@premierparkinglogistics.com">info@premierparkinglogistics.com</a></p>
        <div className="padding-bottom"></div>
      </div>
    </div>
  );
}

function ContactForm(props) {
  const [complete, setComplete] = useState(false);
  return (
    <div className='contact content-body'>
      <h2>Contact</h2>
      <h3>{complete ? 'Message sent!' : ''}</h3>
      <Formik
        initialValues={{
          name: '',
          email: '',
          reference: '',
          address: '',
          message: '',
        }}
        validationSchema={ContactSchema}
        onSubmit={ async (values, actions) => {
          actions.setSubmitting(true);
          return fetch(API_ROOT, {
            body: JSON.stringify(values),
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
          })
          .then(res => res.json())
          .then(res => {
            actions.setStatus({ submitted: true });
            actions.resetForm();
            setComplete(true);
          })
          .catch(err => {
            actions.setStatus({ err });
            actions.resetForm();
          });
        }}>
        {({ errors, touched, status, isSubmitting }) => (
          <Form className='form-group'>
            <p>{isSubmitting ? 'Message sent!' : ''}</p>
            {isSubmitting ? (
              <Spinner />
            ) : (
              <div>
                <Field name="name" className='form-control' placeholder='Name' />
                {errors.name && touched.name ? (
                  <div>{errors.name}</div>
                  ) : null}
                <br />
                <Field name="email" type="email" className='form-control' placeholder='E-Mail' />
                  {errors.email && touched.email ? <div>{errors.email}</div> : null}
                <br />
                <Field name="reference" className='form-control' placeholder='Reference Number' />
                  {errors.email && touched.email ? <div>{errors.email}</div> : null}
                <br />
                <Field
                  name="address"
                  component="textarea"
                  className='form-control message'
                  placeholder='Address' />
                {errors.address && touched.address ? (
                  <div>{errors.address}</div>
                  ) : null}
                <br />
                <Field
                  name="message"
                  component="textarea"
                  className='form-control message'
                  placeholder='Message...' />
                {errors.message && touched.message ? (
                  <div>{errors.message}</div>
                  ) : null}
                <br />
                <button type="submit" className='btn btn-dark'>Submit</button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Contact;

