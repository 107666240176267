import { NavLink, useLocation } from "react-router-dom";
import './header.css';

const siteName = process.env.SITE_NAME || 'Premier Parking Logistics';

const routes = [
  {
    name: 'Home',
    path: '/',
  },
  {
    name: 'About',
    path: '/about',
  },
  {
    name: 'Clients',
    path: '/clients',
  },
  {
    name: 'Areas Covered',
    path: '/areas',
  },
  {
    name: 'Pay & Display',
    path: '/pay-and-display',
  },
  {
    name: 'Residential Management',
    path: '/residential',
  },
  {
    name: 'Appeals',
    path: '/appeals',
  },
  {
    name: 'Contact',
    path: '/contact',
  },
];

const NavItem = ({ name, path, currentRoute }) => {
  const isCurrent = currentRoute === path;
  return (
    <NavLink
      to={path}
      exact
      activeClassName="bg-gray-900 text-white"
      aria-current={isCurrent ? "page" : undefined}
      className={isCurrent
        ? "bg-black text-white rounded-md px-3 py-2 text-sm font-medium"
        : "text-white hover:bg-grey hover:text-white rounded-md px-3 py-2 text-sm font-medium"
      }>
        {name}
      </NavLink>
  )
}

const MobileNavItem = ({ name, path, isCurrent }) => {
  return (
    <NavLink
      to={path}
      exact
      activeClassName="bg-gray-900 text-white"
      aria-current={isCurrent ? "page" : undefined}
      className={isCurrent
        ? "bg-gray-900 text-white block rounded-md px-3 py-2 text-base font-medium"
        : "text-gray-300 hover:bg-gray-700 hover:text-white block rounded-md px-3 py-2 text-base font-medium"
      }>
        {name}
      </NavLink>
  )
}

function Header() {
  const location = useLocation();
  const currentRoute = location.pathname;

	return (
    <div>
      <nav className="bg-orange">
        <div className="hidden md:block mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="flex items-center">
              <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                  {routes.map((route, index) => <NavItem key={index} currentRoute={currentRoute} {...route} />)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="md:hidden" id="mobile-menu">
          <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
            {routes.map((route, index) => <MobileNavItem key={index} isCurrent={currentRoute === route.path} {...route} />)}
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;
