import Breaker from "../breaker";

type Props = {
  title: string;
  subtitle?: string;
};

const PageTitle: React.FC<Props> = ({ title, subtitle }) => {
  return (
    <div className="page-title mt-8 mx-auto text-center">
      <h1 className="text-2xl mx-auto">{title}</h1>
      {subtitle && <h2>{subtitle}</h2>}
      <Breaker />
    </div>
  );
};

export default PageTitle;
