import { Link } from 'react-router-dom';

const siteName = process.env.REACT_APP_SITE_NAME;
const siteUrl = process.env.REACT_APP_SITE_URL;
const phone = process.env.REACT_APP_PHONE_NUMBER;

function Footer({ props }) {
  return (
    <div className="fixed bottom-0 w-full bg-black text-white text-center">
      <div className="container mx-auto p-4 text-sm lg:text-lg">
        <p><Link to="/">Home</Link> | <Link to="/about">About</Link> | <Link to="/appeals">Appeals</Link> | <Link to="/contact">Contact</Link> | <Link to="/pay-and-display">Pay and Display</Link> | <Link to="/residential">Residential</Link> | <Link to="/privacy-policy">Privacy Policy</Link></p>
        <p>Tel: {phone} | Email: <a href={`mailto:info@${siteUrl}`}>info@{siteUrl}</a></p>
        <p>This site and all its content © {siteName}</p>
      </div>
    </div>
  );
}

export default Footer;