import Splash from '../../components/splash';
import PageTitle
 from '../../components/page-title';
import img from './img.jpg';

const PayAndDisplay = () => (
  <div className='h-screen'>
    <Splash src={img} title='Pay & Display' />
    <div className='p-4'>
      <PageTitle title="Pay & Display" />
      <div className="space-y-4">
        <p>Pay & Display is one of our more popular managerdoment systems, it is by far the simplest and most cost effective means for you to begin to generate regular income from your land.</p>

        <p>Using Premier Parking Logistics Pay and Display Machines can increase your profit margins by more than 50% We currently provide pay and display parking systems to an array of different sized companies from large office complexes to individual land owners.</p>

        <p>All our customers have benefitted largely from the income generated from the land, and also the parking control that it offers.</p>

        <p>We offer multiple different pay and display parking systems geared for you to gain the most from your land.</p>

        <h2>Pay and Display Lease Package</h2>
        <p>We can lease the land or parking area from you to guarantee you a monthly income.</p>

        <p>This pay and display option is excellent if you do not have the time to take control and monitor your parking area effectively.</p>

        <p>This option offers complete peace of mind all you have to do is sit back wait for the money.</p>

        <h2>Pay and Display Profit Share Package</h2>
        <p>Is where we provide the capital for the pay and display machines, signage & operational costs and give you a percentage of the revenue generated.</p>

        <p>A great way if you do not have the starting capital for a pay and display parking system.</p>

        <p>Whatever your pay and display requirement may be, we will create a tailored solution to maximise the revenue generated from your car park.</p>

        <p><b>Full Premier Parking Logistics Pay and Display Systems include:</b></p>
        <ul className="list-disc list-inside space-y-2">
          <li className="text-lg">Full installation of pay and display machines</li>
          <li className="text-lg">Full maintenance of pay and display system</li>
          <li className="text-lg">Ticket replenishment</li>
          <li className="text-lg">Regular cash collections</li>
          <li className="text-lg">Reconciliation of cash generated</li>
          <li className="text-lg">Manned Patrols to encourage car park compliance</li>
        </ul>
      </div>
    </div>
  </div>
);

export default PayAndDisplay;

