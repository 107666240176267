import './splash.css';
import { Image } from 'react-bootstrap';

function Splash({ src, alt, title }) {
  return (
    <div className='splash hidden md:block'>
      <div className='overlay'>
        <h1 className='mt-10'>{title}</h1>
      </div>
      <Image src={src} alt={alt} />
    </div>
  );
}

export default Splash;
