import Splash from '../../components/splash';
import Breaker from '../../components/breaker';

import img from './parking-small.jpg';
import PageTitle from '../../components/page-title';

const AreasCovered = () => (
  <div className='h-screen text-center mb-10'>
    <Splash src={img} title='Areas We Cover' />
    <div className='p-4'>
      <PageTitle title='Areas We Cover' />
      <div className=''>
        <div className=''>
          <p className='mt-4'>Here at Premier Parking Solutions we are proud to serve businesses and land owners all across the UK.</p>

          <p className='mt-4'>Helping small and large businesses and landowners in the all cities you see on the right.</p>

          <p className='mt-4'>We offer each of the companies that we serve a completely unique service based around their individual needs.</p>

          <p className='mt-4'>Through helping every customer we have generated the best profit from their land and to ensure they receive a service that meets their needs entirely we have been able to expand to all of these areas. Still we stay in close contact with all our customers using teams in each of these areas.</p>

          <p className='mt-4'>Ensuring that every customer of ours is treated equally with a personal service, regardless of the size of the company.</p>
        </div>
        <Breaker />
        <div>
          <ul className="grid grid-cols-4 gap-2">
            <li className="py-2">Aberdeen</li>
            <li className="py-2">Bangor</li>
            <li className="py-2">Bath</li>
            <li className="py-2">Birmingham</li>
            <li className="py-2">Bradford</li>
            <li className="py-2">Brighton And Hove</li>
            <li className="py-2">Bristol</li>
            <li className="py-2">Cambridge</li>
            <li className="py-2">Canterbury</li>
            <li className="py-2">Cardiff</li>
            <li className="py-2">Carlisle</li>
            <li className="py-2">Chester</li>
            <li className="py-2">Chichester</li>
            <li className="py-2">City Of London</li>
            <li className="py-2">Coventry</li>
            <li className="py-2">Derby</li>
            <li className="py-2">Dundee</li>
            <li className="py-2">Durham</li>
            <li className="py-2">Edinburgh</li>
            <li className="py-2">Ely</li>
            <li className="py-2">Exeter</li>
            <li className="py-2">Glasgow</li>
            <li className="py-2">Gloucester</li>
            <li className="py-2">Hereford</li>
            <li className="py-2">Inverness</li>
            <li className="py-2">Kingston Upon Hull</li>
            <li className="py-2">Lancaster</li>
            <li className="py-2">Leeds</li>
            <li className="py-2">Leicester</li>
            <li className="py-2">Lichfield</li>
            <li className="py-2">Lincoln</li>
            <li className="py-2">Liverpool</li>
            <li className="py-2">Manchester</li>
            <li className="py-2">Newcastle Upon Tyne</li>
            <li className="py-2">Newport</li>
            <li className="py-2">Norwich</li>
            <li className="py-2">Nottingham</li>
            <li className="py-2">Oxford</li>
            <li className="py-2">Peterborough</li>
            <li className="py-2">Plymouth</li>
            <li className="py-2">Portsmouth</li>
            <li className="py-2">Preston</li>
            <li className="py-2">Ripon</li>
            <li className="py-2">Salford</li>
            <li className="py-2">Salisbury</li>
            <li className="py-2">Sheffield</li>
            <li className="py-2">Southampton</li>
            <li className="py-2">St Albans</li>
            <li className="py-2">St Davids</li>
            <li className="py-2">Stirling</li>
            <li className="py-2">Stoke-On-Trent</li>
            <li className="py-2">Sunderland</li>
            <li className="py-2">Truro</li>
            <li className="py-2">Wakefield</li>
            <li className="py-2">Wells</li>
            <li className="py-2">Westminster</li>
            <li className="py-2">Winchester</li>
            <li className="py-2">Wolverhampton</li>
            <li className="py-2">Worcester</li>
            <li className="py-2">York</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
);

export default AreasCovered;
