import { Image } from 'react-bootstrap';
import { Slide } from 'react-slideshow-image';
import Splash from '../../components/splash';
import PageTitle from '../../components/page-title';
import 'react-slideshow-image/dist/styles.css'

import Breaker from '../../components/breaker';
import splashImage from './carpark-home.jpeg';
import carpark from './carpark.jpg';

const siteName = process.env.REACT_APP_SITE_NAME;

const properties = {
  duration: 5000,
  transitionDuration: 500,
  infinite: true,
  indicators: true,
  arrows: true
};

const images = [
  { file: require('./bargainbooze.png'), title: 'Bargain  Booze' },
  { file: require('./coral.png'), title: 'Coral' },
  { file: require('./la fitness.png'), title: 'LA Fitness' },
  { file: require('./lee longlands.png'), title: 'Lee Longlands' },
  { file: require('./network rail.png'), title: 'Network Rail' },
  { file: require('./pdsa.png'), title: 'PDSA' },
  { file: require('./safynet1.png'), title: 'Safety Net' },
  { file: require('./specsavers.png'), title: 'Specsavers' },
  { file: require('./stead--simpson1.png'), title: 'Stead Simpson' },
  { file: require('./Superdrug.png'), title: 'Superdrug' },
];

function Home() {
  return (
    <div className='h-screen'>
      <Splash src={carpark} title={`Welcome to ${siteName}`} />
      <div className='p-4'>
        <PageTitle title="Home" />
        <div className='flex flex-wrap'>
          <div className='w-full md:w-2/3 p-4 text-center'>
            <h2 className='text-4xl pb-8'>At {siteName} we strive to be the best for the customer.</h2>
            <div className='text-gray-700 text-lg'>
              <p className="mb-4">Meaning we tailor make parking enforcement systems based on your individual needs, to ensure you you generate
                the maximum profit from your land and that your parking problems are solved completely.
                Our Parking Enforcement and control methods are designed to be as simple as possible for the customer, and can
                be set up in a matter of days.</p>
              <p className="mb-4">Our main focus as a company is to offer peace of mind to the customer and offer a service that is second to none.
                We can even offer land clearance, which means if you have waste land in an area that people would like to park.</p>
              <p className="mb-4">We will not only offer a full parking solution for the land to turn it into a paying carpark, but we will also
                clear the land completely. </p>
              <p className="mb-4">Allowing you the customer to simply reap the rewards your unused land has to offer. Without having to worry about
                a thing.</p>
            </div>

            <Breaker />

            <div className='flex flex-wrap'>
              <div className='w-full pt-4'>
                <h2 className='text-amber-500 text-2xl font-bold mx-auto text-center'>Our clients</h2>
                <div className="">
                <Slide properties={properties}>
                  {images.map((slideImage, index)=> (
                    <div key={index} className="mx-auto text-center mt-10">
                      <img src={slideImage.file} alt={slideImage.title} className="mx-auto" />
                      <h2>{slideImage.title}</h2>
                    </div>
                  ))}
                </Slide>
              </div>
            </div>
          </div>
          </div>
          <div className='md:w-1/3 p-4 flex-auto text-center mx-auto justify-center'>
            <div className="bg-white shadow-lg rounded px-8 pt-6 pb-8 mb-4">
              <Image
                src={splashImage}
                className='mx-auto'
                alt='Car Park'
              />
              <blockquote className="mt-4 text-gray-700 text-xl">
                "We tailor make parking solutions based on your individual needs, to maximise your potential income."
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
